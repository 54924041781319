<template>
    <b-container fluid class="py-5">
        <b-card-text>
            <p>El siguiente formulario le permite actualizar los datos que ha registrado en nuestro sistema. Los campos marcados con asterisco (*) son de uso obligatorio. </p>
        </b-card-text>
        <b-card-text class="mt-10">
            <b-tabs content-class="mt-3" lazy>
                <b-tab title="Información de Usuario">
                    <ValidationObserver ref="userInfo" v-slot="{ handleSubmit, invalid }">
                        <b-form @submit.prevent="handleSubmit(updateUserInfo)">
                            <b-form-row>
                                <b-col b-col cols="12" md="6">
                                    <ValidationProvider name="Nombre"  rules="required|min:3" v-slot="{ errors, valid }">
                                        <b-form-group 
                                            label="Nombre:*"
                                            label-for="nombre">
                                            <b-form-input
                                            id="nombre"
                                            v-model="user.nombre"
                                            type="text"
                                            autocomplete="off"
                                            required
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Ingrese su nombre"
                                            ></b-form-input>
                                            <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col b-col cols="12" md="6">
                                    <b-form-group
                                        label="Email:"
                                        label-for="Email">
                                        <b-form-input
                                        id="Email"
                                        v-model="user.email"
                                        readonly
                                        type="text"
                                        autocomplete="off"
                                        required
                                        placeholder="Ingrese su email"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col b-col cols="12" md="6">
                                    <ValidationProvider name="Telefono"  rules="required|digits:9" v-slot="{ errors, valid }">
                                        <b-form-group
                                            label="Telefono:*"
                                            label-for="telefono">
                                            <b-form-input
                                            id="telefono"
                                            v-model="user.telefono"
                                            type="text"
                                            autocomplete="off"
                                            required
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Ingrese su telefono"
                                            ></b-form-input>
                                            <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="6">
                                    <ValidationProvider name="departamento"  rules="required" v-slot="{ errors, valid }">
                                        <b-form-group 
                                            label="Departamento:"
                                            label-for="departamento">
                                            
                                            <b-form-select
                                                v-model="user.departamento_id"
                                                :options="departamentos_list"
                                                id="departamento"
                                                value-field="id"
                                                text-field="nombre"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                @change="getCiudadesList()"
                                                >
                                                <template #first>
                                                    <b-form-select-option value="">SELECCIONE</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                            <b-col cols="12" md="6">
                                <ValidationProvider name="ciudad"  rules="required" v-slot="{ errors, valid }">
                                    <b-form-group 
                                        label="Ciudad:"
                                        label-for="ciudad">
                                    
                                        <b-form-select
                                            v-model="user.ciudad_id"
                                            :options="ciudades_list"
                                            id="ciudad"
                                            value-field="id"
                                            text-field="nombre"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template #first>
                                                <b-form-select-option value="">SELECCIONE</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" md="12">
                                <ValidationProvider name="direccion"  rules="required|min:3" v-slot="{ errors, valid }">
                                    <b-form-group 
                                        label="Dirección:"
                                        label-for="direccion">
                                        <b-form-input
                                            id="direccion"
                                            v-model="user.direccion"
                                            type="text"
                                            autocomplete="off"
                                            required
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Ingrese la dirección"
                                            ></b-form-input>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            </b-form-row>
                            <b-alert v-if="messageUserInfoUpdate" :variant="alertVariant" show> {{ messageUserInfoUpdate }}</b-alert>
                            <b-button :disabled="invalid" @click.prevent="updateUserInfo(user)" variant="info">Actualizar</b-button>
                        </b-form>
                    </ValidationObserver>
                </b-tab>
                <b-tab title="Password">
                    <ValidationObserver ref="password" v-slot="{ handleSubmit, invalid }">
                        <b-form @submit.prevent="handleSubmit(updatePassword)">
                            <b-form-row>
                                <b-col b-col cols="12" md="6">
                                    <b-form-group
                                        id="input-group-1"
                                        label="Contraseña Actual:*"
                                        label-for="input-1">
                                        <b-form-input
                                        id="input-1"
                                        v-model="password.current_password"
                                        type="password"
                                        autocomplete="off"
                                        required
                                        placeholder="Ingrese su contraseña"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col b-col cols="12" md="6">
                                    <ValidationProvider name="Nueva Contraseña"  rules="required|min:8" v-slot="{ errors, valid }">
                                        <b-form-group 
                                            id="input-group-2"
                                            label="Nueva Contraseña:*"
                                            label-for="input-2">
                                            <b-form-input
                                            id="input-2"
                                            v-model="password.new_password"
                                            type="password"
                                            autocomplete="off"
                                            required
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Ingrese su contraseña"
                                            ></b-form-input>
                                            <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col b-col cols="12" md="6">
                                    <ValidationProvider name="Confirmar Contraseña"  rules="required|min:8" v-slot="{ errors, valid }">
                                        <b-form-group
                                            id="input-group-3"
                                            label="Confirmar contraseña:*"
                                            label-for="input-3">
                                            <b-form-input
                                            id="input-3"
                                            v-model="password.confirm_password"
                                            type="password"
                                            autocomplete="off"
                                            required
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Confirme su contraseña"
                                            ></b-form-input>
                                            <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-form-row>
                            <b-alert v-if="messagePasswordUpdate" :variant="alertVariant" show>
                                {{ messagePasswordUpdate }}
                            </b-alert>
                            <b-button :disabled="invalid" type="submit" variant="info">
                                Actualizar
                            </b-button>
                        </b-form>
                    </ValidationObserver>
                </b-tab>
            </b-tabs>
        </b-card-text>
    </b-container>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapState('auth', ['user']),
    },
    data(){
        return{
            messageUserInfoUpdate: "",
            password:{
                current_password: "",
                new_password: "",
                confirm_password: "",
            },
            messagePasswordUpdate: "",
            alertVariant: "",
            departamentos_list: [],
            ciudades_list: [],
        }
    },
    created(){
        this.getDepartamentosList();
    },
    methods:{
        ...mapMutations(['setLoading']),
        async updateUserInfo(user){
            this.setLoading(true)
            this.messageUserInfoUpdate = "";
            let datosUsuario = { 
                                nombre: user.nombre,
                                telefono: user.telefono,
                                departamento_id: user.departamento_id,
                                ciudad_id: user.ciudad_id,
                                direccion: user.direccion,
                                }
            await this.$conectar.post('/perfil/actualizar', datosUsuario)
            .then(response =>{
                if(response.data.error == false){
                    this.messageUserInfoUpdate = "Actualización realizada con éxito";
                    this.alertVariant = "success";
                }else{
                    this.messageUserInfoUpdate = response.data.mensaje;
                    this.alertVariant = "error";
                }
            })
            .catch(response =>{
                console.log(response);
                this.messageUserInfoUpdate = "Parece que hubo un error en el servidor, contacte al administrador";
                this.alertVariant = "error";
            })
            this.setLoading(false)
        },

        async updatePassword(){
            this.setLoading(true)
            this.messagePasswordUpdate = "";
            await this.$conectar.post('/perfil/actualizar/password', this.password)
            .then(response =>{
                if(response.data.error == false){
                    this.messagePasswordUpdate = "Actualización realizada con éxito";
                    this.alertVariant = "success";
                }else{
                    this.messagePasswordUpdate = response.data.mensaje;
                    this.alertVariant = "danger";
                }
            })
            .catch(response =>{
                console.log(response);
                this.messagePasswordUpdate = "Parece que hubo un error en el servidor, contacte al administrador";
                this.alertVariant = "danger";
            })
            this.setLoading(false)
            this.password.current_password = "";
            this.password.new_password = "";
            this.password.confirm_password = "";
            this.$nextTick(() => {
                this.$refs.password.reset()
            })
        },

        async getDepartamentosList() {
            this.setLoading(true)
            await this.$conectar.post('direcciones/get-departamentos-list')
            .then(response =>{
                this.departamentos_list = response.data.departamentosList;
                if(this.user.departamento_id){
                    this.getCiudadesList();
                }
            })
            .catch(e => {
                console.log(e.response);
            })
            this.setLoading(false)
        },
        async getCiudadesList() {
            this.departamentoSelected = { 
                departamento_id:   this.user.departamento_id, 
            }
            this.setLoading(true)
            await this.$conectar.post('direcciones/get-ciudades-list', this.departamentoSelected)
            .then(response =>{
                this.ciudades_list = response.data.ciudadesList;
            })
            .catch(e => {
                console.log(e.response);
            })
            this.setLoading(false)
        },
    }
}
</script>